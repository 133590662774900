button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
.button-inscricao{
  background-color: green;
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
  color: white;
  width: 250px;
  height: 50px;
}
.button-inscricao:hover{
  background-color: rgb(98, 187, 98);  
}

.button-nova-inscricao{
  font-size: 1.5rem;
  background-color: #0175b0;
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
  color: white;
  width: 250px;
  height: 60px;
}
.button-nova-inscricao:hover{
  background-color: rgb(16 90 127);  
}
.button-disabled{
  background-color: rgb(143, 142, 142);
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  color: white;
  font-size: 1.4rem;
}
.button-success{
  background-color: orange;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  color: white;
  font-size: 1.4rem;
}
.button-acessar{
  background-color: #f57c00 !important;
  line-height: 3rem;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.375em;
  padding: 0 0.5rem;
  height: 3rem;
  font-weight: bold;
  text-decoration: none;
}
.button-undefined{
  background-color: rgb(46, 121, 2);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  color: white;
  font-size: 1.4rem;
}
.button-consulta{
  background-color: rgb(16 90 127);
  font-size: 1.5rem;
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
  color: rgb(253, 253, 253);
  width: 250px;
  height: 60px;
}
.button-consulta:hover{
  background-color: #0175b0;
  
}
button.learn-more {
  width: 100%;
  height: auto;
}
button.learn-more .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon.arrow {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
button.learn-more .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}
button:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow {
  background: #fff;
  -webkit-transform: translate(1rem, 0);
          transform: translate(1rem, 0);
}
button:hover .button-text {
  color: #fff;
}
