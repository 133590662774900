:root {
  --cor-tema: #006499;
  --cor-tema-claro: #1d8dc9;
  --cor-primaria: rgb(10,99,167);
  --cor-secundaria: rgb(17,131,17);
  --cor-terciaria: rgb(176,22,22);
  --cor-amarelo: rgb(220,204,26);

  --cor-preta: #000;
  --cor-branca: #FFF;
  --cor-cinza-claro: #EEE;
  --cor-cinza-escuro: #222;
  --cor-cinza-medio: rgba(68, 68, 68, 0.747);
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--cor-branca);
  font-family: 'Roboto', sans-serif;
}

.full-width{width: 100%;}
.full-height{height: 100vh;}

/* FLEX */
.flex{  display: flex;}
.horizontal{flex-direction: row;}
.vertical{flex-direction: column;}
.space-between{justify-content: space-between;}

.flex-1{flex: 1;}
.flex-2{flex: 2;}
.flex-3{flex: 3;}
.flex-4{flex: 4;}
.flex-5{flex: 5;}
.flex-6{flex: 6;}
.flex-7{flex: 7;}
.flex-8{flex: 8;}

.flex-start{
  justify-content: flex-start;
  align-items: center;
}
.flex-end{
  justify-content: flex-end;
  align-items: center;
}
.flex-center{
  justify-content: center;
  align-items: center;
}

.grid-1{
  width: 100%;
}
.grid-2{
  width: 45%;
}
.grid-3{
  width: 30%
}
.Container-Btn-Print{
width: 80%;
margin: 0 auto;
margin-top: 50px;
}
.Container-Btn-Print button{
background-color: rgb(224, 147, 4);
padding: 15px;
color: white;
border-radius: 5px;
}
.Show-Inscricao-Situacao{
text-align-last: center;
}
.Show-Inscricao-Situacao select{
width: 30%;
font-size: 1.4rem;
text-align: center;
height: 40px;
}
.Show-Inscricao-Situacao textarea{
width: 30%;
font-size: 1.4rem;
height: 200px;
}
.Show-Inscricao-Situacao button{
background-color: green;
color: white;
width: 10%;
padding: 10px;
border-radius: 20px;
}
.Container-Link-Menu{
  align-self: center;
  margin-right: 200px !important;
}
.menu-dashboard{
  text-align: left !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Link-Menu {
  text-decoration: none !important;
  color: white;
  font-size: 1.3rem;
  margin: 50px;
}
.Link-Menu:hover {
  color: rgb(172, 172, 172);
}
.header_index{
background-color: #004680;
width: 100%;
}
.header_index img{
width: 50% !important;
  }
.top-head{
  padding: 0.5rem 0;
  background-color: #004680;
}

  .Inicial span{
    font-size: 2rem;
  }
  .Inicial .Button{
    text-align: center;
    margin-top: 30px;
  }
  .Img-Index{
    width: 80%;
    margin: 0 auto;
  }
  .Img-Index a{
    width: 90%;
  }
  .Img-Index a img{
    width: 80%;
    height: 100%;
  }
.Form-Inscricao{
  width: 80%;
  margin: 0 auto;
}
.Form-Inscricao form{
  width: 100% !important;
  margin-top: 20px;
  text-align: center;
}
.Form-Inscricao .Select-Simples{
  width: 100% !important;
  margin-top: 20px;
  text-align: center;
}
.Form-Inscricao .Button{
text-align: center;
margin: 20px auto;
}
.Form-Inscricao-Container .MuiFormControl-root{
  width: 90%;
}
.container-slide-index{
  flex-wrap: wrap;
}
.container-slide-index .Input-Arquivo {
    margin: 20px;
    max-width: calc(30% - 20px);
    background-color: whitesmoke;
    padding: 20px;
    } 
 
.container-slide-index span{
font-weight: 700;
font-size: 1.2rem;
text-align: center;
}

.Inscricoes{
  width: 80%;
  margin: 0 auto;
}
.footer {
  width: 80%;
  margin: 20px auto;
 }
 
 .contato{
   font-size: 15pt !important;
   text-align: justify;
 }
 .Img-Footer{
   width: 100%;
 }
 .Img-Footer img{
   width: 90%;
   padding: 10px;
 }


 .Input-Protocolo{
  text-align: center;
  margin: 50px 0;
 }
 .Input-Protocolo input{
  width: 400px;
 }

 .Documentos a{
align-self: center;
 }
 .Show-Inscricao{
   width: 80%;
   margin: 0 auto;
   text-align: -webkit-center;
 }
 .Show-Inscricao-Container{
   flex-direction: row;
 }
 .Show-Inscricao-Container-upload{
  flex-direction: row;
  place-content: center;
  margin-top: 50px;
  width: 70%;
}
 .Show-Inscricao span{
font-size: 2rem;
 }

 .Login .Card{
   width: 80%;
   margin: 0 auto;
   text-align: center;
 }
 .Login .Card p{
  font-size: 2rem;
}
 .Login .Card form .MuiFormControl-root{
  width: 40%;
  margin: 20px auto;
  text-align: center;
}
.Button{
  width: 40%;
  margin: 0 auto;
}
.button-nova-inscricao{
justify-content: center;
}
.Links-Uteis{
  width: 60%;
  margin: 0 auto;
}
.Links-Uteis p {
  color: #004680;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 50px;
}
.Links-Uteis a {
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
}
.Links-Uteis a:hover {
color: #004680
}

.contagem{
background-color: #ffffff;
text-align: -webkit-center;
}
.contagem span{
font-size: 1.5rem;
}
.numero
{
min-width: 20px;
max-width: 55px;
background-color: #efefef;
color: #000000;
font-size: 22px;
margin: 5px;
text-align: center;
border-radius: 5px;
padding: 5px;
}

.legenda{
height: 25px;
line-height: 10px;
font-size:12px;
background-color: #ffffff;
text-align: center;
}
.links a{
color: white;
font-size: 1.2rem;
padding: 10px;
}
.faixa-menu{
  width: 50%;
  height: 10px;
  background-color: #06a3d4;
  position: absolute;
}
.faixa-footer{
    width: 50%;
  height: 10px;
  float: right;
  margin-top: -10px;
  background-color: #06a3d4;
}
.copy{
  float: left;
  width: 100%;
  background-color: #004680;
  text-align: center;
  margin-top: 4rem;
}
.copy p{
  width: 100%;
  color: white;
  padding: 1rem 0;
  margin: 0;
  font-family: "Roboto", sans-serif !important;
}
#main-nav{
  display: flex;
  flex-flow: wrap;
  padding: 1rem 0;
  background-color: #eeeeff;
}
.msg-login h6{
color: white;
}
.Inicial span{
  font-size: 1.3rem;
  font-weight: bold;
}
@media screen and (max-width: 720px){
  .Button{
    width: 100%;
  }
  .Login .Card form .MuiFormControl-root{
    width: 100%;
  }
  .menu-dashboard{
    text-align: center !important;
    width: 100%;
    display: flex;
  }
  .header_index a{
width: auto;
  }
  .header_index img{
    width: 60% !important;
    margin: 20px 0 !important;
      }

      .Inicial span{
        font-size: 1.5rem;
      }
      .Inicial .Botoes{
        flex-direction: column;
      }
      .Img-Index{
        flex-direction: column;
        width: 100%;
      }
      .Img-Index a{
        width: 90%;
        align-self: center;
      }
      .Img-Index a img{
        width: 100%;
        margin-top: 20px;
      }
  .Form-Inscricao-Container{
    flex-flow:wrap
  }
  .Form-Inscricao {
    width: 100%;
  }
  .Img-Footer img{
    width: 100%;
  }

  .Input-Protocolo .MuiFormControl-root{
    width: 80% !important;
  }
  .Show-Inscricao-Container{
    flex-direction: column;
    margin-top: 50px;
    background: rgb(232, 230, 230);
    width: 100% !important;
    padding: 30px;
    border-radius: 0 !important;
  }
  .Show-Inscricao-Container-upload{
    flex-direction: column;
    margin-top: 50px;
    background: rgb(232, 230, 230);
    width: 100% !important;
    padding: 30px;
    border-radius: 0 !important;
    place-content: center;
  }
  .Show-Inscricao{
width: 100%;
text-align: initial
  }
  .footer{
    flex-direction: column;
  }
  .Footer{
    position: relative;
  }
  .contato{
    font-size: 13pt !important;
    text-align: center;
  }
}

    #printable img{
   display: none;
  }
 .Documentos-Entregues-Bloco-2{
   margin-left: 50px;
  }
  .Modal-Documentos {
    width: 80%;
    border: 2px solid #000;
    padding: 16px 32px 24px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    background-color: #fff;
  }
  .Modal-Container{
    height: 670px;
    flex-grow: 1;
    transform: translateZ(0);
  }
@media print {
  .Modal-Container{
    height: 1500px;
    flex-grow: 1;
    transform: translateZ(0);
  }
  .Modal-Documentos {
    width: 100%;
    height: auto;
    border: 2px solid #000;
    padding: 16px 32px 24px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    background-color: #fff;
  }
  .footer-pma {
    display: none;
  }
  .header_index{
    display: none;
  }
 .Form-Inscricao {
    display: none;
  }
  #printable, #printable * {
    display: block;    
  }
  #printable {
    position: fixed;
    left: 0;
    top: 150px;
    width: 100%;
    height: auto;
  }
    #printable img{
    display: block;
    width: 50%;
    margin-top: -150px;
    margin-left: 150px
  }
  .Documentos-Entregues-Bloco-2{
    margin-left: 0;
  }
  .Container-Btn-Print{
    display: none;
  }
}

.Button-Download{
  background-color: #004680;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.upbutton{
  background-color: green;
  color: white;
  border-radius: 10px;
  padding: 5px 0;
}